import * as React from 'react'
import { Link } from 'gatsby'
import { FaTwitter, FaInstagram, FaLinkedinIn, FaVimeoV } from 'react-icons/fa';


export default function Footer () {
    return (
        <div className="bg-white mt-32 mb-16 sm:mt-0 sm:mb-0">
          <div className="max-w-full mx-auto py-18 px-8 sm:px-6 lg:py-32 lg:px-20 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl leading-9 font-semibold tracking-tight text-black sm:text-4xl sm:leading-10">
              We always work in collaboration.
              <br/>
              <span className="text-pink-900">Follow our work and become a friend.<br/></span>
              <div className="mt-7 md:mt-5">
  
                <a className='pr-2' href="https://www.instagram.com/urbanscaleinterventions" target="_blank" rel="noreferrer">
                  <span className="rounded-full p-2 h-11 w-11 inline-flex items-center justify-center bg-gray-700 text-white hover:text-gray-300"><FaInstagram/></span></a>
  
                <a className='px-2' href="https://twitter.com/urbanscaleint" target="_blank" rel="noreferrer">
                  <span className="rounded-full p-2 h-11 w-11 inline-flex items-center justify-center bg-gray-700 text-white hover:text-gray-300"><FaTwitter/></span></a>
  
                <a className='px-2' href="https://www.linkedin.com/company/urban-scale-interventions" target="_blank" rel="noreferrer">
                  <span className="rounded-full p-2 h-11 w-11 inline-flex items-center justify-center bg-gray-700 text-white hover:text-gray-300"><FaLinkedinIn/></span></a>
  
                <a className='px-2' href="https://vimeo.com/user90877314" target="_blank" rel="noreferrer">
                  <span className="rounded-full p-2 h-11 w-11 inline-flex items-center justify-center bg-gray-700 text-white hover:text-gray-300"><FaVimeoV/></span></a>
  
              </div>

              <Link to="/privacy" className="text-sm">Privacy Policy & GDPR</Link>
  
            </h2>
            <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
              <p className="md:text-right">Portview Trade Centre<br/> 310 Newtownards Rd, Belfast BT4 1HE
                <br/><span className="font-bold text-black hover:text-black"><a className="text-black hover:text-black"
                    href="mailto:studio@urbanscaleinterventions.com">studio@urbanscaleinterventions.com</a></span><br/><br/>Designed
                and developed by <span className="font-heading">USI</span></p>
            </div>
          </div>
        </div>
    )
}