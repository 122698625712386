import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";

import Slide from 'react-reveal/Slide';



export default function OurPlaceinSpace() {
  return (
    <Layout pageTitle="Our Place in Space">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Our Place in Space - USI Projects</title>
        <meta name="description" content="We have been working with world renowned artist Oliver Jeffers to realize a vision for Unboxed 2022. Our Place in Space is a playful experiment."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

         <section class="text-gray-700 body-font heading-container-placeinspace">

         <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16"> Our Place in Space</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Unboxed 2022</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-purple-700">OVERVIEW
                </h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                  We have been working with world renowned artist Oliver Jeffers to realize a vision for Unboxed 2022. 
                  Our Place in Space is a playful experiment that asks:  What is the difference between ‘US’ and ‘THEM’? 
                  What happens to your perspective when you look back at earth from space? Centered around an epic scale model 
                  of the solar system designed by the artist Oliver Jeffers, Our Place in Space combines a three-dimensional 
                  sculpture trail, interactive  AR app and exciting learning and events programmes.
                </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Technology<br/>Communities<br/>Resilience<br/>Public Space<br/>Culture<br/>Climate<br/></p>
                  <p class="text-2xl font-bold uppercase mt-8">Services</p>
                  <p class="my-4 text-gray-500 leading-relaxed mt-0">Engagement
                    Urban Strategies<br/>
                    Installations<br/>
                    <a className="text-xs" target="_blank" href="https://www.ourplaceinspace.earth">ourplaceinspace.earth</a></p>
              </div>
            </div>
          </section>
        </div>
      </section>


      <div class="md:my-16 md:py-16 w-full px-5 md:px-15 py-4">
        <img class="py-2 w-full mx-auto" src="/img/projects/coastal-walk.jpg" alt="BBC Our Place in Space"/>
      </div>

      <div class="w-full py-12 lg:px-20 bg-purple-700">
        <div class="lg:w-4/6">
          <Slide right>
            <h3 className="text-2xl md:text-5xl font-heading px-5 md:px-20 mx-auto text-white">
            What is the difference between</h3>
            <h3 className="text-2xl md:text-5xl font-heading px-5 md:px-20 mx-auto text-white">‘US’ and ‘THEM’?</h3>
            <h3 className="text-2xl md:text-5xl font-heading px-5 md:px-20 mx-auto text-white">
            What happens to your perspective<br/>when you look back at earth from space?
            </h3>
          </Slide>
        </div>
      </div>

     

        <div class="flex flex-wrap md:p-16 md:m-16 px-5 py-8">
          <div class="lg:w-3/6 lg:px-5">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl  py-5 lg:py-0">
              The Our Place in Space sculpture trail will  stretch over 10 kilometers and will travel from a riverside location in 
              Derry / Londonderry (April / May) to Divis and Black Mountain in Belfast (June / July), before heading to Cambridge 
              (August) and returning to Northern Ireland at the Transport Museum and North Down Coastal Path (September / October).
            </p>
          </div>
          <div class="lg:w-3/6 lg:px-5">
            <img src="/img/projects/OJandCollab.jpg"  alt="Oliver Jeffers"/>            
          </div>
        </div>

        <div class="flex flex-wrap md:p-16 md:m-16 px-5 py-8">
          <div class="lg:w-3/6 lg:px-5">
            <img src="/img/projects/named-planets.jpg"  alt="Named Planets"/>            
          </div>
          <div class="lg:w-3/6 lg:px-5">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl  py-5 lg:py-0">
              STEAM-based learning and wide-ranging events programmes will explore what it means to live on Earth in 2022, 
              and how we might better share and protect our planet in future. Our Place in Space is Northern Ireland’s 
              project for UNBOXED: Creativity in the UK, commissioned by  Belfast City Council.
            </p>
          </div>
        </div>

        <div class="md:my-16 md:py-16 w-full px-5 md:px-15 py-4">
          <img class="py-2 w-full mx-auto" src="/img/projects/sun-drawing.png" alt="Sun Art"/>
        </div>

        <div class="flex flex-wrap  md:p-16 md:m-16 px-5 py-8">
          <div class="lg:w-3/6 lg:px-5">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl  py-5 lg:py-0">
              Part visitor attraction, part immersive learning experience, Our Place in Space will bring together 
              millions of people across the UK in an act of purposeful and meaningful inquiry. Our Place in Space asks: 
              Which side are we on? Are we ‘US’ or are we ‘THEM’? And should there really be any ’sides’ at all once 
              we view ourselves, alone on a tiny planet – the only one that can harbour life – from the vastness of outer space?
            </p>
          </div>
          <div class="lg:w-3/6 lg:px-5">
            <img src="/img/projects/jeffers-drawings.png"  alt="Oliver Jeffers"/>            
          </div>
        </div>

        <div class="md:py-16 w-full px-5 lg:px-15 py-4 mx-auto items-center bg-purple-700">
          <h3 data-scroll data-scroll-speed="1" class="text-2xl lg:text-4xl font-heading text-white text-center">
            VISIT OUR OUR PLACE IN SPACE, Oliver Jeffers AND UNBOXED HERE:<br/><br/>
            <a target="_blank" className="text-3xl lg:text-5xl text-white" href="https://www.ourplaceinspace.earth">www.ourplaceinspace.earth</a><br/>
            <a target="_blank" className="text-3xl lg:text-5xl text-white" href="https://www.oliverjeffers.com">www.oliverjeffers.com</a><br/>                                     
            <a target="_blank" className="text-3xl lg:text-5xl text-white" href="https://www.unboxed2022.uk">unboxed2022.uk</a><br/>
          </h3>
        </div>

        <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-purple-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../lighting" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
