import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { FaBars, FaTimes } from 'react-icons/fa';
import Hamburger from "./Hamburger";


export default function NavbarTwo() {

  const [top, setTop] = useState(true);

  const [showMenu, setShowMenu] = useState(false);


  let menu

  if(showMenu) {


    menu = 
    <div className="h-full w-full bg-gray-800 fixed top-0 z-40">
       <div className="pt-16">
         <div className="grid grid-cols-2 gap-6 mx-auto">
                  <div className="p-2 border-r-2 border-white">
                  <ul className="row">
                    <h3 className="text-2xl text-white p-2">STUDIO</h3>
                    <li className="menu1__item text-xl text-white">
                      <Link className="p-2 " to="/">HOME</Link>
                    </li>
                    <li className="menu1__item text-xl text-white">
                      <Link className="p-2" to="/projects">PROJECTS</Link>
                    </li>
                    <li className="menu1__item text-xl text-white">
                      <Link className="p-2"to="/team">TEAM</Link>
                    </li>
                    <li className="menu1__item text-xl text-white">
                      <Link to="/services" className="p-2">SERVICES</Link>
                    </li>
                    <li className="menu1__item text-xl text-white">
                      <Link to="/approach" className="p-2">APPROACH</Link>
                    </li>
                    <li className="menu1__item text-xl text-white">
                      <Link to="/blog" className="p-2">BLOG</Link>
                    </li>
                   
                    <li className="menu1__item menu__item--contact mt-auto">
          
                      <div className="menu1__info">
                      </div>
                    </li>
                  </ul>
                  </div>
                  <div className="p-2">
                  <ul className="row">
                    <h3 className="text-2xl text-white p-2">SOCIAL</h3>
                    <li className="menu1__item text-xl text-white">
                      <a className="p-2" target="_blank" href="https://www.instagram.com/urbanscaleinterventions/">INSTAGRAM</a>
                    </li>
                    <li className="menu1__item text-xl text-white">
                    <a className="p-2" target="_blank" href="https://twitter.com/urbanscaleint">TWITTER</a>
                    </li>
                    <li className="menu1__item text-xl text-white">
                    <a className="p-2" target="_blank" href="https://www.linkedin.com/company/urban-scale-interventions">LINKEDIN</a>
                    </li>
                    <li className="menu1__item text-xl text-white">
                    <a className="p-2" target="_blank" href="https://medium.com/urban-scale-interventions">MEDIUM</a>
                    </li>
                    <li className="menu1__item menu__item--contact mt-auto">
          
                      <div className="menu1__info">
                      </div>
                    </li>
                  </ul>
                  </div>
            </div>
            <div className="grid grid-cols-2 gap-6 text-xs absolute bottom-10 text-white">
              <div>
              <p className="p-4">Belfast - Málaga<br/><br/>Portview Trade Centre,<br/>310 Newtownards Rd,<br/>Belfast BT4 1HE<br/>
                <a className="" href="mailto:studio@urbanscaleinterventions.com"> studio@urbanscaleinterventions.com</a></p>
              </div>
              <div>
              
              </div>
            </div>
          </div>
    </div>
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);


    return (
      <div>
      <header className={`fixed w-full z-50 md:bg-opacity-80 transition duration-300 ease-in-out ${!top && 'bg-gray-900 blur shadow-lg'} hidden md:block`}>
      <div className=" mx-auto px-6 md:px-12 lg:px-20">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex-shrink-1md:mr-4">
            <Link to="/" className="block">
            <img className="mt-6 md:mt-0 md:mx-auto w-4/12 block xl:hidden" src='/img/logos/usi.png'  alt="USI" />
            <img className="mt-6 md:mt-0 md:mx-auto w-96 hidden xl:block" src='/img/logos/usi-animated-logo.gif'  alt="USI" />
            </Link>
          </div>

          <div className="">
                  <ul className="menu1__list">
                    <li className="menu1__item text-xs lg:text-sm px-5 text-white">
                      <Link className="menu1__link p-1" activeClassName="" to="/">HOME</Link>
                    </li>
                    <li className="menu1__item text-xs lg:text-sm px-5 text-white">
                      <Link className="menu1__link p-1 " activeClassName="" to="/projects">PROJECTS</Link>
                    </li>
                    <li className="menu1__item text-xs lg:text-sm px-5 text-white">
                      <Link className="menu1__link p-1" activeClassName="" to="/team">TEAM</Link>
                    </li>
                    <li className="menu1__item text-xs lg:text-sm px-5 text-white">
                      <Link to="/services" className="menu1__link p-1" activeClassName="">SERVICES</Link>
                    </li>
                    <li className="menu1__item text-xs lg:text-sm px-5 text-white">
                      <Link to="/approach" className="menu1__link p-1" activeClassName="">APPROACH</Link>
                    </li>
                    <li className="menu1__item text-xs lg:text-sm px-5 text-white">
                      <Link to="/blog" className="menu1__link p-1" activeClassName="">BLOG</Link>
                    </li>
                    
                   
                  </ul>
          </div>

        </div>
      </div>
    </header>

    <nav className={`fixed w-full z-50 md:bg-opacity-80 transition duration-300 ease-in-out ${!top && 'bg-gray-900 blur shadow-lg'} block md:hidden`}>
    <div className="flex items-center justify-between h-16 md:h-20">
          <div className="p-5">
            <Link to="/" className="block">
            <img className="md:mx-auto w-10" src='/img/logos/usi.png'  alt="USI" />
            </Link>
          </div>
      <span className="p-5 right-0 fixed">
        <div className="menu-btn" 
        onClick={() => setShowMenu(!showMenu)}>
          <div>
            <Hamburger isOpen={showMenu}/>
          </div>
        </div>
      </span>
    </div>
    </nav>
    { menu }



    </div>
    )}