import * as React from 'react'
import NavbarTwo from './NavbarTwo'
import Footer from './Footer'
import '../styles/global.css'


export default function Layout ({children}) {
  return (
    <div className="layout">
      <NavbarTwo/>
      <div className="">
        { children }
      </div>
      <Footer />
    </div>
  )
}